<template>
  <div>
    <POSMInput
      :input-type="inputType"
      :side="side"
      :posm-category-data="posmCategoryData"
      :posm-category-data-index="posmCategoryDataIndex"
      :posm-submission-uuid="posmSubmissionUuidParent"
      :employee-id="employeeId"
      :selected-campaign="selectedCampaignParent"
      :posm-category='posmCategory'
    />
    <div class="text-danger bold">
      <p v-if="posmCategoryData.note && posmCategoryData.note.length > 0">
        Ghi chú: {{ posmCategoryData.note }}
      </p>
      <p v-if="posmCategoryData.size && posmCategoryData.size.length > 0">
        Kích thước: {{ posmCategoryData.size }}
      </p>
      <p
        v-if="
          posmCategoryData.startDate && posmCategoryData.startDate.length > 0
        "
      >
        Ngày bắt đầu:
        {{ formatDate }}
      </p>
      <p v-if="posmCategoryData.endDate && posmCategoryData.endDate.length > 0">
        Ngày kết thúc:
        {{ formatDate }}
      </p>
    </div>
  </div>
</template>
<script>
import POSMInput from '@/view/components/posm/POSMInput.vue';

export default {
  name: 'PosmFillIn',
  components: { POSMInput },
  props: {
    datetimeFormat: {},
    inputType: {},
    formatDate: {},
    posmCategoryData: {},
    posmCategoryDataIndex: {},
    posmSubmissionUuidParent: {},
    employeeId: {},
    selectedCampaignParent: Object,
    side: {
      type: String,
    },
    posmCategory: {
      type: Object,
    }
  },
};
</script>
