<template>
  <KTCodePreview v-bind:title="'Báo cáo POSM'" class="preview-box">
    <template v-slot:preview>
      <div
        v-if="!isEmpty(selectedCampaign) && KTUtil.isBreakpointDown('sm')"
        :class="
          `d-flex justify-content-between align-items-center px-6 py-3 animate-fade-down fill-in-header ${headerCampaignBackgroundStyle()}`
        "
      >
        <div>
          <p class="font-weight-bold font-size-lg">
            Bạn đang điền:
            <span class="font-weight-normal">{{ selectedCampaign.name }}</span>
          </p>
          <p class="mb-1">
            Từ {{ selectedCampaign.startDate }} đến
            {{ selectedCampaign.endDate }}
          </p>
        </div>
        <b-badge :variant="headerBadgeVariantStyle()" size="lg" pill
          >{{ selectedCampaign.dataCount }} / {{ selectedCampaign.posmCount }}
        </b-badge>
      </div>
      <b-form v-if="show" enctype="multipart/form-data" class="form-height">
        <b-row class="mb-4">
          <b-col
            sm="12"
            md="5"
            class="
              'mb-6 position-relative'
            "
          >
            <div :class="`${stickyInfoClass}`" :style="stickyInfoStyle">
              <b-card-text>
                <h5>Thông tin của bạn</h5>
              </b-card-text>

              <div class="mb-8">
                <b-list-group>
                  <b-list-group-item>
                    <b-skeleton
                      v-if="!userFullName"
                      width="100%"
                      height="1.5rem"
                    />
                    <div class="d-flex justify-content-between" v-else>
                      <div>Họ và tên:</div>
                      <div>
                        {{ userFullName }}
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-skeleton
                      v-if="
                        !userStoreName && !storeList && storeList.length < 1
                      "
                      width="100%"
                      height="1.5rem"
                    />
                    <div
                      class="d-flex align-items-center justify-content-between"
                      v-else
                    >
                      <div>
                        Điền cho cửa hàng:
                      </div>
                      <div v-if="targetUsers.includes(employee.jobTitleId)">
                        {{ userStoreName }}
                      </div>
                      <div v-else>
                        <AutoSuggest
                          :model="apiParams.storeKeyword"
                          v-model="form.storeId"
                          placeholder="tên cửa hàng"
                          :suggestions="filteredStoreList"
                          @select="onSelectedStore"
                          @change="onInputChangeStore"
                          suggestion-name="name"
                          :limit="100"
                          required
                          text-align="right"
                          class="mb-1"
                        >
                        </AutoSuggest>
                        <span v-if="!form.storeId" class="text-danger"
                          >Bạn cần nhập tên cửa hàng để tiếp tục</span
                        >
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item v-if="form.storeId">
                    <b-skeleton
                      v-if="!userStoreAddress"
                      width="100%"
                      height="1.5rem"
                    />
                    <div class="d-flex justify-content-between" v-else>
                      <div>
                        Tại địa chỉ:
                      </div>
                      <div>
                        {{ userStoreAddress }}
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item v-if="form.storeId">
                    <b-skeleton
                      v-if="!userStoreAreaName"
                      width="100%"
                      height="1.5rem"
                    />
                    <div class="d-flex justify-content-between" v-else>
                      <div>
                        Thuộc khu vực:
                      </div>
                      <div>
                        {{ userStoreAreaName }}
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div v-if="form.storeId" class="h-auto">
                <h5>
                  Có
                  <b-skeleton
                    v-if="!campaignOptions.length"
                    width="12px"
                    height="100%"
                    class="skeleton-campaign-num"
                  ></b-skeleton>
                  <span v-else>{{ campaignOptions.length }}</span>
                  Campaign cần bạn điền
                </h5>
                <CampaignSkeleton
                  v-if="!campaignOptions.length"
                  :campaign-options="campaignOptions"
                  :quantity="5"
                />
                <b-list-group
                  class="skeleton-campaign-list"
                  id="campaign-list"
                  v-else
                >
                  <b-list-group-item
                    button
                    v-for="campaign of campaignOptions"
                    :class="
                      `d-flex justify-content-between align-items-center ${
                        !selectedActiveCampaign(campaign.id) &&
                        campaign.dataCount === campaign.posmCount
                          ? 'bg-success text-white'
                          : ''
                      } `
                    "
                    v-bind:key="`campaign-option-${campaign.name}}`"
                    @click="campaignOnChangeHandler(campaign.id)"
                    :active="selectedActiveCampaign(campaign.id)"
                    :disabled="!!disableCampaignSelection || !!isLoadingImage"
                  >
                    <div @click.stop="campaignOnChangeHandler(campaign.id)">
                      <p class="font-weight-bold">{{ campaign.name }}</p>
                      <p class="mb-1">
                        Từ {{ campaign.startDate }} đến {{ campaign.endDate }}
                      </p>
                    </div>
                    <b-badge
                      @click.stop="campaignOnChangeHandler(campaign.id)"
                      :variant="listItemBadgeVariantStyle(campaign)"
                      size="lg"
                      pill
                      >{{ campaign.dataCount }} / {{ campaign.posmCount }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="7" id="posm-fill-in" v-if="form.storeId">
            <transition name="fade">
              <div class="border rounded mt-10 p-6 fill-in-box">
                <div class="fill-in-text-box" v-if="onActiveCampaign">
                  <i
                    :class="
                      `flaticon-exclamation-1 attention-icon text-${notice.color}`
                    "
                  ></i>
                  <h3 :class="`attention-heading text-${notice.color}`">
                    {{ notice.message }}
                  </h3>
                </div>
                <div v-if="isLoadingPosms" class="mb-4">
                  <b-skeleton
                    class="mb-4"
                    width="100%"
                    height="18px"
                  ></b-skeleton>
                  <b-skeleton
                    class="mb-1"
                    width="33%"
                    height="16px"
                  ></b-skeleton>
                  <b-skeleton width="100%" height="120px"></b-skeleton>
                  <b-skeleton
                    class="mb-1"
                    width="33%"
                    height="16px"
                  ></b-skeleton>
                  <b-skeleton
                    class="mb-8"
                    width="100%"
                    height="120px"
                  ></b-skeleton>

                  <b-skeleton
                    class="mb-4"
                    width="100%"
                    height="18px"
                  ></b-skeleton>
                  <b-skeleton
                    class="mb-1"
                    width="33%"
                    height="16px"
                  ></b-skeleton>
                  <b-skeleton width="100%" height="120px"></b-skeleton>
                  <b-skeleton
                    class="mb-1"
                    width="33%"
                    height="16px"
                  ></b-skeleton>
                  <b-skeleton width="100%" height="120px"></b-skeleton>
                </div>
                <div
                  v-bind:key="'interior-categories'"
                  v-else-if="
                    form.employeeId &&
                      form.storeId &&
                      form.posmFormUuid &&
                      form.campaignId &&
                      !isLoadingPosms
                  "
                >
                  <h4
                    v-if="form.store.interior && form.store.interior.length > 0"
                  >
                    Bên trong:
                  </h4>
                  <b-row
                    cols="12"
                    v-for="(posmCategory, categoryIndex) of form.store.interior"
                    v-bind:key="`interior-form-categories-${categoryIndex}`"
                  >
                    <b-col>
                      <div class="shadow-sm p-6 mb-8 bg-white rounded">
                        <h5>Hạng mục: {{ posmCategory.name }}</h5>

                        <div
                          v-for="(posmCategoryData,
                          posmCategoryDataIndex) of posmCategory.data"
                          v-bind:key="
                            `exterior-form-categories-${posmCategoryDataIndex}`
                          "
                          class="mb-6"
                        >
                          <PosmFillIn
                            :datetime-format="DATETIME_FORMAT"
                            :input-type="ENUM.POSM_INPUT_TYPE"
                            :format-date="
                              formatDate(
                                posmCategoryData.startDate,
                                DATETIME_FORMAT,
                              )
                            "
                            :posm-category-data="posmCategoryData"
                            :posm-category-data-index="posmCategoryDataIndex"
                            :posm-submission-uuid-parent="
                              form.posmSubmissionUuid
                            "
                            :employee-id="form.employeeId"
                            :selected-campaign-parent="selectedCampaign"
                            side="interior"
                            :posm-category="posmCategory"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <div
                  v-if="
                    form.employeeId &&
                      form.storeId &&
                      form.posmFormUuid &&
                      form.campaignId &&
                      !isLoadingPosms
                  "
                  v-bind:key="'exterior-categories'"
                >
                  <h4
                    v-if="form.store.exterior && form.store.exterior.length > 0"
                  >
                    Bên ngoài:
                  </h4>
                  <b-row
                    cols="12"
                    v-for="(posmCategory, categoryIndex) of form.store.exterior"
                    v-bind:key="`exterior-form-categories-${categoryIndex}`"
                  >
                    <b-col>
                      <div class="shadow-sm p-6 mb-8 bg-white rounded">
                        <h5>Hạng mục: {{ posmCategory.name }}</h5>

                        <div
                          v-for="(posmCategoryData,
                          posmCategoryDataIndex) of posmCategory.data"
                          v-bind:key="
                            `exterior-form-categories-${posmCategoryDataIndex}`
                          "
                          class="mb-6"
                        >
                          <PosmFillIn
                            :datetime-format="DATETIME_FORMAT"
                            :input-type="ENUM.POSM_INPUT_TYPE"
                            :format-date="
                              formatDate(
                                posmCategoryData.startDate,
                                DATETIME_FORMAT,
                              )
                            "
                            :posm-submission-uuid-parent="
                              form.posmSubmissionUuid
                            "
                            :posm-category-data="posmCategoryData"
                            :posm-category-data-index="posmCategoryDataIndex"
                            :selected-campaign-parent="selectedCampaign"
                            side="exterior"
                            :posm-category="posmCategory"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </transition>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import SelectOption from '@/view/pages/posm/models/SelectOption';

import createFirstSelectionOption from '@/view/pages/posm/utils/createFirstSelectionOption';
import ENUM, { JOB_TITLE, POSM_TYPE } from '@/utils/enum';
import { cmdUrl } from '@/utils/apiUrl';
import { formatDate, getRemainingDays } from '@/utils/date';
import {
  DATETIME_FORMAT,
  FORM_DATA_POSM_SUBMIT,
  SUCCESS_RESPONSE_STATUS,
  TIME_TRIGGER,
  TIMEOUT_TO_DISABLE_BUTTON,
  TIMEOUT_TO_SCROLL_TO,
} from '@/utils/constants';
import { makeToastFaile } from '@/utils/common';
import StoreFromServer from '@/view/pages/posm/models/StoreFromServer';
import POSMCategory from '@/view/pages/posm/models/POSMCategory';
import { cloneDeep, isEmpty, isNumber, toNumber, uniqBy } from 'lodash';
import POSMCategoryData from '@/view/pages/posm/models/POSMCategoryData';
import moment from 'moment/moment';
import PosmFillIn from '@/view/components/posm/PosmFillIn.vue';
import CampaignSkeleton from '@/view/components/posm/campaign/skeleton/CampaignSkeleton.vue';
import CampaignOption from '@/view/pages/posm/models/CampaignOption';
import KTUtil from '@/assets/js/components/util';
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import debounce from 'debounce';
import commonMixins from '@/view/mixins/common.mixins';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { swalMixin } from '@/view/mixins';
import { EventBus } from '@/core/services/event-bus';

function extractCategoriesAndTheirPositionData(data, isExterior) {
  let categories = data.data.Posms.filter(
    (posm) => posm.isExterior === isExterior,
  ).map(
    (posm) =>
      new POSMCategory(
        posm.PosmCategory.name,
        [],
        posm.PosmCategory.id,
        posm.PosmCategory.inputType,
      ),
  );
  categories = uniqBy(categories, 'id');
  categories.forEach((category, index, outerArr) => {
    for (const posm of data.data.Posms) {
      if (posm.PosmCategory.id === category.id) {
        if (posm.isExterior === isExterior) {
          outerArr[index].data.push(
            new POSMCategoryData(
              posm.name,
              null,
              posm.PosmCategory.inputType,
              posm.startDate,
              posm.endDate,
              posm.position,
              posm.id,
            ),
          );
        }
      }
    }
  });
  return categories;
}

function setReceivedImageFile(submissionData, imageExtension, categoryData) {
  const imageType = `image/${imageExtension}`;
  categoryData.data = new File(
    [
      new Blob([new Uint8Array(submissionData.data.data)], {
        type: imageType,
      }),
    ],
    `${submissionData.fileName}.${imageType}`,
    { type: imageType, lastModified: new Date() },
  );
}

function setIndividuals(submissionData, categoryData) {
  categoryData.individuals = submissionData.individuals;
}

export default {
  components: {
    CampaignSkeleton,
    PosmFillIn,
    KTCodePreview,
    AutoSuggest,
  },
  mixins: [commonMixins, swalMixin],
  data() {
    return {
      ENUM,
      form: {
        areaId: null,
        storeId: null,
        employeeId: null,
        campaignId: null,
        posmFormUuid: null,
        posmSubmissionUuid: null,
        store: {},
      },
      searchArea: '',
      areaOptions: [createFirstSelectionOption('khu vực')],
      storeOptions: [createFirstSelectionOption('cửa hàng')],
      employeeOptions: [createFirstSelectionOption('quản lý')],
      campaignOptions: [],
      formOptions: [],
      show: true,
      payload: null,
      isSubmitting: false,
      DATETIME_FORMAT,
      showTopShadow: false,
      showBottomShadow: true,
      isLoadingPosms: false,
      dataCount: 0,
      selectedCampaign: {},
      apiParams: {
        storeKeyword: '',
      },
      filteredCampaignList: [],
      filteredStoreList: [],
      disableCampaignSelection: false,
      isLoadingImage: false,
      notice: {
        message: 'Hãy lựa chọn một Campaign',
        color: 'warning',
      },
      targetUsers: [
        JOB_TITLE.QL,
        JOB_TITLE.TL,
        JOB_TITLE.HV_QL,
        JOB_TITLE.HV_TL,
      ],
      highAuthorityUsers: [
        JOB_TITLE.GD,
        JOB_TITLE.RSM,
        JOB_TITLE.ASM,
        JOB_TITLE.QL_MKT,
        JOB_TITLE.MKT,
        JOB_TITLE.ADMIN_MKT,
        JOB_TITLE.TRADE_MKT,
        JOB_TITLE.TRADE_MKT_MANAGER,
        JOB_TITLE.GDNH,
      ],
      socket: null,
    };
  },
  methods: {
    isEmpty,
    formatDate,
    fetchStores() {
      ApiService.get(`/stores?areaId=${this.form.areaId}&limit=10000&page=1`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.storeOptions = data.data.list_store.map(
              (item) => new SelectOption(item.address, item.id),
            );
            this.storeOptions.unshift(createFirstSelectionOption('cửa hàng'));
          }
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Kiểm tra lại kết nối internet');
          } else {
            makeToastFaile(err.message);
          }
        });
    },

    fetchCampaignsByStoreId() {
      if (!this.form.storeId) return;
      ApiService.query(cmdUrl.Campaign.root + '/stores/' + this.form.storeId)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            if (data.data.length < 1) {
              makeToastFaile('Không có Campaign nào cho cửa hàng này');
              return;
            }

            this.campaignOptions = data.data.map((campaign) => {
              const now = moment().format(DATETIME_FORMAT);
              const formattedStartDate = formatDate(
                campaign.startDate,
                DATETIME_FORMAT,
              );
              const formattedEndDate = formatDate(
                campaign.endDate,
                DATETIME_FORMAT,
              );
              return new CampaignOption({
                id: campaign.id,
                name: campaign.name,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                remainingDays: getRemainingDays(now, formattedEndDate),
                posmCount: campaign.posmCount,
                dataCount: campaign.dataCount ?? 0,
              });
            });
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    fetchPosmForm() {
      if (!this.form.storeId || !this.form.campaignId) {
        return;
      }
      ApiService.get(
        cmdUrl.POSMForm.root +
        '/store/' +
        this.form.storeId +
        '/campaign/' +
        this.form.campaignId,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.form.posmFormUuid = data.data[0].uuid;
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        })
        .finally(() => {
          if (this.form.posmFormUuid) {
            this.fetchPosms();
            this.postSubmission();
            return;
          }
          this.form.store = Object.assign({});
        });
    },
    fetchPosms() {
      if (!this.form.posmFormUuid) {
        return;
      }
      this.isLoadingPosms = true;
      this.form.store = {};
      ApiService.get(
        cmdUrl.POSMForm.root + '/posm-info/' + this.form.posmFormUuid,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.form.store = {};
            const interiorCategories = extractCategoriesAndTheirPositionData(
              data,
              POSM_TYPE.INTERIOR,
            );
            const exteriorCategories = extractCategoriesAndTheirPositionData(
              data,
              POSM_TYPE.EXTERIOR,
            );
            this.form.store = Object.assign(
              {},
              new StoreFromServer('', interiorCategories, exteriorCategories),
            );
          }
        })
        .catch((err) => {
          makeToastFaile(err.response ? err.response.message : 'Lỗi');
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoadingPosms = false;
            this.disableCampaignSelection = false;
          }, TIMEOUT_TO_DISABLE_BUTTON);
        });
    },
    updateCategoryDataWithSubmissionItems(
      categories,
      posmIdToSubmissionItemUuid,
    ) {
      for (const category of categories) {
        for (const categoryData of category.data) {
          const submissionData = posmIdToSubmissionItemUuid[categoryData.id];
          if (submissionData) {
            categoryData.posmSubmissionItemUuid = submissionData.uuid;
            let imageExtension;
            if (submissionData.data.data) {
              setReceivedImageFile(
                submissionData,
                imageExtension,
                categoryData,
              );
              setIndividuals(submissionData, categoryData);
            } else {
              categoryData.data = null;
            }
          }
        }
      }
      this.isLoadingImage = false;
    },

    postSubmission() {
      if (
        !this.form.storeId ||
        !this.form.employeeId ||
        !this.form.posmFormUuid
      ) {
        return;
      }
      ApiService.post(`${cmdUrl.PosmFormSubmission.root}`, {
        campaignId: this.form.campaignId,
        storeId: this.form.storeId,
        posmFormUuid: this.form.posmFormUuid,
      })
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.form.posmSubmissionUuid = data.data.posmSubmissionUuid;
            const posmIdToSubmissionItemUuid = data.data.posmSubmissionItems.reduce(
              (result, item) => {
                result[item.posmId] = {
                  uuid: item.uuid,
                  data: item.data,
                  extension: item.extension,
                  fileName: item.fileName,
                  individuals: item.individuals,
                };
                return result;
              },
              {},
            );

            const formStoreKeys = [
              FORM_DATA_POSM_SUBMIT.INTERIOR_CATEGORIES,
              FORM_DATA_POSM_SUBMIT.EXTERIOR_CATEGORIES,
            ];

            for (const key of formStoreKeys) {
              if (this.form.store.hasOwnProperty(key)) {
                this.updateCategoryDataWithSubmissionItems(
                  this.form.store[key],
                  posmIdToSubmissionItemUuid,
                );
              }
            }
          }
        })
        .catch((err) => {
          makeToastFaile(err.response ? err.response.message : 'Lỗi');
        });
    },
    fetchAreaInfo(id) {
      ApiService.get(`area/posm/${id}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.employee.store.area.name = data.data.name;
          }
        })
        .catch((err) => {
          makeToastFaile(err.response.message);
        });
    },
    fetchStoreInfo(id) {
      if (!id) return;
      ApiService.get(`stores/${id}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            const { name, address, areaId } = data.data;
            this.employee.store.name = name;
            this.employee.store.address = address;
            this.employee.store.area.id = areaId;
            this.fetchAreaInfo(this.employee.store.area.id);
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    campaignOnChangeHandler(campaignId) {
      this.form.campaignId = null;
      this.selectedCampaign = {};

      this.form.campaignId = (isNumber(campaignId) && campaignId) || '';
      this.selectedCampaign = this.campaignOptions.find(
        (campaign) => campaign.id === toNumber(campaignId),
      );
      const distanceToTop = document.getElementById('posm-fill-in').offsetTop;
      this.disableCampaignSelection = true;

      setTimeout(() => {
        window.scrollTo({ top: distanceToTop, behavior: 'smooth' });
      }, TIMEOUT_TO_SCROLL_TO);
    },
    dataCountForStoreCampaigns() {
      let count = 0;
      for (const outerKey in this.form.store) {
        if (
          [
            FORM_DATA_POSM_SUBMIT.INTERIOR_CATEGORIES,
            FORM_DATA_POSM_SUBMIT.EXTERIOR_CATEGORIES,
          ].includes(outerKey)
        ) {
          for (const category of this.form.store[outerKey]) {
            for (const posm of category.data) {
              const isFile =
                posm.data !== null &&
                posm.data !== '' &&
                posm.data instanceof File;
              if (isFile) {
                count++;
              }
            }
          }
        }
      }
      for (const campaign of this.campaignOptions) {
        if (campaign.id === toNumber(this.form.campaignId)) {
          campaign.dataCount = count;
        }
      }
    },
    selectedActiveCampaign(campaignId) {
      return toNumber(this.form.campaignId) === toNumber(campaignId);
    },
    fetchStoresByJobTitle() {
      ApiService.get(
        `/stores/posms/get-by-title?storeKeyword=${this.apiParams.storeKeyword}`,
      )
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.storeList = this.filteredStoreList = data.data;
          }
        })
        .catch((err) => {
          makeToastFaile(err.response ? err.response.data.message : 'Lỗi');
        });
    },
    onSelectedStore(option) {
      this.resetCampaignSelection();
      this.form.storeId = option.item.id;
      this.fetchStoreInfo(this.form.storeId);
      this.fetchCampaignsByStoreId();
    },
    onInputChangeStore(text) {
      if (text === '' || text.length < this.apiParams.storeKeyword.length) {
        this.resetForm();
      }

      this.apiParams.storeKeyword = text;
      this.form.storeId = null;
      this.filteredStoreList = cloneDeep(this.storeList);
      this.storeList = [];

      this.debounceInput();
    },
    debounceInput: debounce(function() {
      this.fetchStoresByJobTitle();
      this.disableCampaignSelection = false;
      this.isLoadingImage = false;
      this.isLoadingPosms = false;
    }, TIME_TRIGGER),
    headerCampaignBackgroundStyle() {
      return !this.selectedActiveCampaign(this.form.campaignId) &&
        this.selectedCampaign.dataCount === this.selectedCampaign.posmCount
        ? 'bg-success text-white'
        : 'bg-primary text-white';
    },
    headerBadgeVariantStyle() {
      return this.selectedCampaign.dataCount !== this.selectedCampaign.posmCount
        ? 'danger'
        : this.selectedCampaign.dataCount === this.selectedCampaign.posmCount &&
          !this.selectedActiveCampaign(this.selectedCampaign.id)
        ? 'light'
        : 'success';
    },
    listItemBadgeVariantStyle(campaign) {
      return campaign.dataCount !== campaign.posmCount
        ? 'danger'
        : campaign.dataCount === campaign.posmCount &&
          !this.selectedActiveCampaign(campaign.id)
        ? 'light'
        : 'success';
    },
    resetForm() {
      this.employee.store.id = null;
      this.employee.store.name = null;
      this.employee.store.address = null;
      this.employee.store.area.id = null;
      this.employee.store.area.name = null;
      this.form.campaignId = null;
      this.filteredCampaignList = [];
      this.campaignOptions = [];
      this.form.campaignId = null;
      this.selectedCampaign = {};
      this.form.storeId = null;
      this.form.store = {};
      this.form.posmSubmissionUuid = null;
      this.form.posmFormUuid = [];
      this.resetLoading();
    },
    resetCampaignSelection() {
      this.form.campaignId = null;
      this.selectedCampaign = {};
      this.campaignOptions = [];
      this.form.posmSubmissionUuid = null;
      this.form.posmFormUuid = [];
      this.resetLoading();
    },
    resetLoading() {
      this.isLoadingPosms = false;
      this.disableCampaignSelection = false;
      this.isLoadingImage = false;
    },
    getEmployeeInfo() {
      const employeeInfo = this.getEmployeeFromLocal();
      this.employee.name = employeeInfo.fullName;
      this.employee.jobTitleId = employeeInfo.jobTitleId;
      return employeeInfo;
    },
    getEmployeeInfoForForm(employeeInfo) {
      this.form.employeeId = employeeInfo.employeeId;
      this.form.areaId = this.employee.store.area.id = employeeInfo.areaId;
      this.form.storeId = this.employee.store.id = employeeInfo.storeId;
    },
    validateGettingStores() {
      if (
        !this.targetUsers
          .concat(this.highAuthorityUsers)
          .includes(this.employee.jobTitleId)
      ) {
        this.popupSwalError({
          title: 'Bạn không có quyền Báo cáo POSM',
          text:
            'Chức vụ hiện tại của bạn: ' +
            this.getJobTitleFromLocal(this.employee.jobTitleId) +
            '. Do đó, bạn không có quyền Báo cáo POSM. Vui lòng liên hệ với người có thẩm quyền / IT để được hỗ trợ.',
        });
      }
    },
    getInfoBasedOnJobTitle() {
      if (this.highAuthorityUsers.includes(this.employee.jobTitleId)) {
        this.form.storeId = this.employee.store.id = null;
        this.fetchStoresByJobTitle();
      } else if (this.targetUsers.includes(this.employee.jobTitleId)) {
        this.fetchStoreInfo(this.employee.store.id);
        this.fetchCampaignsByStoreId();
      }
    },
  },
  watch: {
    'form.campaignId'() {
      this.fetchPosmForm();
      this.dataCountForStoreCampaigns();
      if (this.form.campaignId) {
        this.isLoadingImage = true;
      }
    },
    'form.store': {
      handler: function() {
        this.dataCountForStoreCampaigns();
      },
      deep: true,
      immediate: true,
    },
    'form.posmSubmissionUuid': {
      handler: function() {
        this.dataCountForStoreCampaigns();
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Báo cáo POSM' }]);
    const employeeInfo = this.getEmployeeInfo();
    this.getEmployeeInfoForForm(employeeInfo);
    this.validateGettingStores();
    this.getInfoBasedOnJobTitle();
    EventBus.$on('revert-to-previous-image', () => {
      if (this.form.posmFormUuid) {
        this.fetchPosms();
        this.postSubmission();
        return;
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('revert-to-previous-image');
  },
  computed: {
    KTUtil() {
      return KTUtil;
    },
    JOB_TITLE() {
      return JOB_TITLE;
    },
    userFullName() {
      return this.employee.name || null;
    },
    userStoreName() {
      return this.employee.store.name || null;
    },
    userStoreAddress() {
      return this.employee.store.address || null;
    },
    userStoreAreaName() {
      return this.employee.store.area.name || null;
    },
    onActiveCampaign() {
      return !this.isLoadingPosms && isEmpty(this.form.store);
    },
    stickyInfoStyle() {
      return { top: `${KTUtil.isBreakpointUp('sm') ? '128px' : undefined}` };
    },
    stickyInfoClass() {
      return `${KTUtil.isBreakpointUp('sm') ? 'position-sticky' : ''}`;
    },
  },
};
</script>
<style scoped>
.skeleton-campaign-num {
  display: inline-block;
  margin-bottom: -3px;
}

.skeleton-campaign-list {
  height: auto;
  overflow-y: auto;
}

.animate-fade-down {
  animation: fadeDown 0.5s ease-in-out;
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fill-in-header {
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 96;
  width: 100%;
}

.attention-icon {
  font-size: 64px;
}

.attention-heading {
  letter-spacing: 0.2px;
  line-height: 1.4;
}

.fill-in-box {
  min-height: 93.45%;
  position: relative;
}

.fill-in-text-box {
  margin-top: 136px;
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.form-height {
  height: fit-content;
}

.preview-box {
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s;
}
</style>
